<template>
    <ODataLookup :data-object="dsProjectPhasesLookup" :whereClause="getWhereClause" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="150"></OColumn>
        <OColumn field="Title" width="300"></OColumn>
    </ODataLookup>
</template>

<script setup>
    import { defineProps, ref, computed } from 'vue';
    import { ODataLookup } from 'o365-datalookup';

    const props = defineProps({
        is: String,
        OrgUnitId: Number,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsProjectPhasesLookup = $getDataObjectById("dsScope_ProjectPhasesLookup");

    const restrictedToContext = ref(true);

    const getWhereClause = computed(() => {
        var vFilter = [];

        if (props.OrgUnitId && restrictedToContext.value) {
            
            vFilter.push("DescendantOrgUnit_ID = " + props.OrgUnitId);
        }

        if (!props.OrgUnitId) {
            vFilter.push("1=2");
        }

        return vFilter.join(" AND ");
    });

     
</script>